@charset 'UTF-8';

// 1. Configuration and helpers
@import
    'utils/variables',
    'utils/functions',
    'utils/mixins',
    'utils/animations';

// // 2. Vendors
@import
    'vendor/normalize';

// 3. Base stuff
@import
    'base/base',
    'base/fonts',
    'base/typography',
    'base/helpers',
    'base/sections';

// 4. Layout-related sections
@import
    'layout/header',
    'layout/footer';

// 5. Components
@import
    'components/buttons',
    'components/icons';

// 6. Page-specific styles
@import
    'pages/index';

// 7. Themes
@import
    'themes/default';