/*@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');*/
@import url('https://fonts.googleapis.com/css?family=ABeeZee');

* {
	font-family: 'ABeeZee', sans-serif;
	/*font-family: "PT Sans", sans-serif;*/
}

body {
	background: #1d2c37;
	overflow-x: hidden;
}

main {
	padding: 45px 50px;
}

.highlighted {
	// position: relative;

	// &:before {
	// 	content: '';
	// 	width: 74px;
	// 	height: 72px;
	// 	position: absolute;
	// 	display: block;
	// 	top: -4px;
	// 	left: -4px;
	// 	border: 4px solid #384c56 !important;
	// 	z-index: 1;
	// }
}

.elementsContainer {
	width: 1225px;
	@include clearfix;
	display: block;
	margin: 0 auto;
	border-right: 1px solid #23343d;
	border-bottom: 1px solid #23343d;
	position: relative;

	&.active {
		border-right: 0;
		border-bottom: 0;
	}

	.innerContainer { 
		@include clearfix;
		margin-top: 50px;

		&.active {
			display: inline-block;
			margin-top: 0px;		

			.element {
				margin: 9px !important;
				border: 1px solid #23343d;
			}			
		}

	}

	.elHover {
		width: 150px;
		height: 141px;
		padding: 7.5px;
		position: absolute;
	    top: 0px;
	    left: 171px;
		background: #FFF;
		color: #1e2d38 !important;

		.left {
			width: 130px;

			.number {
				font-size: 28.16px;
			}

			.symbol {
				font-size: 42.24px;
			}

			.name, .atomic_mass {
				font-size: 19.36px;
			}
		}

	    &.gas {
    		background: #a86cd8 !important;

    		div {
    			color: #1e2d38 !important;
    		}	
	    }

	    &.halogens {
    		background: #00b9e6 !important; 		

    		div {
    			color: #1e2d38 !important;
    		}
	    }    

	    &.otherNon {
    		background: #94f1df !important;	    		

    		div {
    			color: #1e2d38 !important;
    		}
	    }    

	    &.metalloid {
    		background: #5ec488 !important; 	     		

    		div {
    			color: #1e2d38 !important;
    		}
	    }     

	    &.postTrans {
    		background: #dfdd9d !important;     		

    		div {
    			color: #1e2d38 !important;
    		}	
	    }       

	    &.alkaline {
    		background: #a76749 !important;      	    		

    		div {
    			color: #1e2d38 !important;
    		}
	    }       

	    &.alkali {
    		background: #a25260 !important;

    		div {
    			color: #1e2d38 !important;
    		}
	    }       

	    &.lanthanide {
    		background: #e90050 !important;

    		div {
    			color: #1e2d38 !important;
    		}
	    }   

	    &.actinide {
    		background: #f982e2 !important;     		    		

    		div {
    			color: #1e2d38 !important;
    		}
	    }   		
	}

	.types {
		width: 420px;
		position: absolute;
		top: -50px;
		color: #FFF;
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);

		ul {
			margin: 0;
			padding: 0px;
			padding-left: 1px;	
			font-size: 14px;	
			list-style: none;

			li {
				padding-bottom: 8px;
				cursor: pointer;

				&:before {
					content: " ";
					display: inline-block;
					margin-right: 5px;
					position: relative;
					top: 1px;
					border: 1px solid #666666;
					border-radius: 50%;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
					filter: alpha(opacity=60);
					opacity: 0.6;

					-webkit-box-shadow: 0 0 1px 0px rgb( 255, 255, 255);
					box-shadow: 0 0 1px 0px rgb( 255, 255, 255);
					width: 12px;
					height: 12px;
					z-index: 86;

					-webkit-transition: background-color .5s ease-in-out;
					-moz-transition: background-color .5s ease-in-out;
					-o-transition: background-color .5s ease-in-out;
					-ms-transition: background-color .5s ease-in-out;
					transition: background-color .5s ease-in-out;					
				}

				&.gas {

		    		&.active {
						&:before {
							background: #a86cd8 !important; 
						}   			
		    		}

					&:before {
						border-color: #a86cd8 !important;
					}
				}

				&.transMetal {
		    		&.active {
						&:before {
							background: #FFF !important; 
						}   			
		    		}

					&:before {
						border-color: #FFF !important;
					}					
				}

				&.halogens {

		    		&.active {
						&:before {
							background: #00b9e6 !important;    			
						}
		    		}   

					&:before {
						border-color: #00b9e6 !important;
					}
				}				

				&.metalloid {

		    		&.active {
						&:before {
							background: #5ec488 !important; 
						}   			
		    		}   

					&:before {
						border-color: #5ec488 !important;
					}
				}				

				&.otherNon {

		    		&.active {
						&:before {
							background: #94f1df !important;    			
						}
		    		}    

					&:before {
						border-color: #94f1df !important;
					}
				}		

				&.alkali {

		    		&.active {
						&:before {
							background: #a25260 !important;    
						}			
		    		}      	

					&:before {
						border-color: #a25260 !important;
					}
				}		

				&.alkaline {

		    		&.active {
						&:before {
							background: #a76749 !important;    			
						}
		    		}  

					&:before {
						border-color: #a76749 !important;
					}
				}	

				&.lanthanide {

		    		&.active {
		    			&:before {
		    				background: #e90050 !important;    			
		    			}
		    		}        		

					&:before {
						border-color: #e90050 !important;
					}
				}	

				&.actinide {

		    		&.active {
						&:before {
							background: #f982e2 !important;    			
						}
		    		}   

					&:before {
						border-color: #f982e2 !important;
					}
				}	

				&.transition {
					&:before {
						border-color: #FFF !important;
					}
				}			

				&.postTrans {

		    		&.active {
		    			&:before {
		    				background: #dfdd9d !important;
		    			}
		    		}   

					&:before {
						border-color: #dfdd9d !important;
					}
				}																										
			}	
		}

		div span {
			font-size: 16px;
			display: block;
			margin-bottom: 10px;
		}

		.metals {
			float: left;
		}

		.nonMetals {
			float: left;
			margin-left: 35px;
		}
	}
}

.restElementsContainer {
	width: 1225px;
	@include clearfix;
	display: block;
	margin: 0 auto;
	margin-top: 25px;	
	margin-bottom: 5px;

	&.active {
		margin-top: -4px;
	}	

	.innerContainer {
		width: 1021px;
		float: right;
		border-right: 1px solid #23343d;
		border-bottom: 1px solid #23343d;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: -4px;
			left: -3px;
			width: 100.7%;
			height: 140px;
		    border-top: 4px solid #384c56 !important;
		    border-right: 4px solid #384c56 !important;
		    border-bottom: 4px solid #384c56 !important;
		}		

		&.active {
			border: 0;
			float: left;

			.element {
				border: 1px solid #23343d;
				margin: 9px;
			}

			&:before {
				display: none;
			}			
		}		
	}
}

.element {
	width: 68px;
	height: 66px;	
	display: block;
	float: left;
	color: #FFF;
	border-top: 1px solid #23343d;
	border-left: 1px solid #23343d;
    font-family: Arial, "Arial Unicode MS", sans-serif;
    white-space: nowrap;    
    position: relative;
    cursor: pointer;

    &.brd {
    	border-right: 1px solid #23343d;
    }

    &.gas {
    	.left {
    		color: #a86cd8 !important;

    		&:hover {
	    		background: #a86cd8 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}  	
    }

    &.halogens {
    	.left {
    		color: #00b9e6 !important;

    		&:hover {
	    		background: #00b9e6 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}  	    		
    }    

    &.otherNon {
    	.left {
    		color: #94f1df !important;

    		&:hover {
	    		background: #94f1df !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}      	   	
    }    

    &.metalloid {
    	.left {
    		color: #5ec488 !important;

    		&:hover {
	    		background: #5ec488 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}      	  	
    }     

    &.postTrans {
    	.left {
    		color: #dfdd9d !important;

    		&:hover {
	    		background: #dfdd9d !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}     	   	
    }       

    &.transMetal {
    	.left {
    		color: #FFF !important;

    		&:hover {
	    		background: #FFF !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}       	  	
    }

    &.alkaline {	
    	.left {
    		color: #a76749 !important;

    		&:hover {
	    		background: #a76749 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}      	
    }       

    &.alkali { 
    	.left {
    		color: #a25260 !important;

    		&:hover {
	    		background: #a25260 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}     	 	
    }       

    &.lanthanide {   
    	.left {
    		color: #e90050 !important;

    		&:hover {
	    		background: #e90050 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}      		
    }   

    &.actinide {
    	.left {
    		color: #f982e2 !important;

    		&:hover {
	    		background: #f982e2 !important;
	    		div {
	    			color: #1e2d38 !important;
	    		}    			
    		}
    	}      	  	
    }              

	&.f1 {
		float: left;
		display: block;
	}

	&.f2 {
		display: block;
		float: right;
		@include clearfix;
	}

	&.f3 {
		clear: both;
	}

	&.f5 {
		float: right;
	}

	.left {
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		vertical-align: top;
		padding: 2px 3px;

		.number {
			@include clearfix;
			font-size: 12.8px;
			float: left;
			display: block;
		}

		.symbol {
			clear: both;
			font-size: 19.2px;
			line-height: 1.1;
			font-weight: bold;
			margin-top: 3px;
			display: block;
			text-align: left;
		}

		.name {
			font-size:11.2px;
			line-height: 1;
			overflow: hidden;
		}

		.atomic_mass {
			font-size: 11.2px;
			line-height: 1.3;
			text-align: left;
			display: block;
			text-overflow: ellipsis;
		}

	}

	div.table_number_x, div.table_number_y {
		display: none;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		font-size: 11px;
		color: #3a535d !important;
		top: -50px;

		> span {
			text-align: center;
			display: block;
			vertical-align: middle;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
		}

		&:hover {
			border: 1px solid #2b3c43;
			color: #c2c4c2 !important;
		}
	}

	&.top {
		div.table_number_x {
			display: block;
			top: -30px;
			left: 24px;
		}
	}

	&.right, &.left {
		div.table_number_y {
			display: block;
			top: 24px;
		}
	}	

	&.right {
		div.table_number_y {
			right: -30px;
		}
	}

	&.left {
		div.table_number_y {
			left: -30px;
		}
	}	

	.right {
		display: inline-block;
	}

	&.f57, &.f89 {

		.number, .name {
			display: none;
		}

		.symbol {
			margin-top: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			font-size: 15px;
		}
	}	

	&.f57 {

		&:before {
			content: '';
			width: 72px;
			height: 161px;
			position: absolute;
			display: block;
			top: -4px;
			left: -4px;
			border-top: 4px solid #384c56 !important;
			border-right: 4px solid #384c56 !important;
			border-left: 4px solid #384c56 !important;
			z-index: 1;
		}		

		div {
			color: #e90050 !important;
		}
	}

	&.f89 {

		&:before {
			content: '';
			width: 72px;
			height: 231px;
			position: absolute;
			display: block;
			top: -4px;
			left: -4px;
			border-bottom: 4px solid #384c56 !important;
			border-left: 4px solid #384c56 !important;
			z-index: 1;
		}			

		div {
			color: #f982e2 !important;
		}
	} 	

    &.gray {
    	//background: #DDD !important;
    	color: #2a3a41 !important;

    	div {
			color: #2a3a41 !important;    		
    	}
    }	
}

aside {
	width: 450px;
	background: #1d2b36;
	position: absolute;
	z-index: 1;
	top: 70px; 
	right: -450px;
	height: 100%;
    transition: 0.7s ease;
    -webkit-transition: 0.7s ease;
    -moz-transition: 0.7s ease;	

    @media (max-width: 768px) {
    	width: 320px;
    }

    &.active { 
    	right: 0px;
    }

	.ic-close {
		border: 1px solid #FFF;
		width: 25px;
		height: 25px;
		display: block;
		border-radius: 50%;
		color: #FFF;
		position: relative;
		margin-top: 15px;
		margin-left: 30px;
		cursor: pointer;

		span {
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			font-size: 20px;
			display: block;
		}
	}

	.magic-container {
		position: absolute;
		right: 0px;
		width: 300px;
		height: 190px;
		top: 0;
	}

	.symbol {
		margin: 20px 0 10px 30px;
		font-size: 65px;
		color: #FFF;
		display: block;
	}

	.name, .atomic_mass {
		font-size: 19px;
		color: #FFF;
		text-transform: uppercase;
		display: block;
		margin-bottom: 2px;
		margin-left: 30px;
		line-height: 1;
	}

	.atomic_mass {
		margin-bottom: 32px;
		margin-left: 30px;
	}

	p {
		font-size: 16px;
		color: #FFF;
		margin: 0 30px;		
	}

	.read-more {
		border: 1px solid #2a3c43;
		width: 140px;
		padding: 10px 15px;
		border-radius: 25px;
		font-size: 16px;
		color: #FFF;
		display: block;
		margin: 0 auto;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		transition: all 0.4s ease;

		&:hover {
			background: #FFF;
			border-color: #FFF;
			color: #2a3c43;
		}
	}

	.row {
		width: 100%;
		font-size: 16px;
		padding: 15px 30px;
		display: block;
		@include clearfix;

		&.odd {
			background: #20303a;
		}

		&.even {
			background: #1d2b36;
		}

		.left, .right {
			width: 50%;
		}

		.left {
			color: #6c7173;
			float: left;
		}

		.right {
			color: #FFF;
			float: right;
			text-align: right;
		}

	}
}

.element.gray {
	color: #2a3a41 !important;

	div {
		color: #2a3a41 !important;    		
	}
}	

#bohr-model {
	width: 400px;
	height: 200px;
	display: block;
	position: absolute;
	top: 0px;
}

.bohr-svg-container{display:inline-block;position:relative;width:100%;padding-bottom: 0px !important; vertical-align:top;overflow:hidden}.bohr-svg-content-responsive{display:inline-block;position:absolute;left:0}.bohr-atomic-symbol{font-family:Futura,Arial,sans-serif}


.box-glow {
  -webkit-box-shadow: inset 3px 4px 71px 0px rgba(24,255,255,1);
  -moz-box-shadow: inset 3px 4px 71px 0px rgba(24,255,255,1);
  box-shadow: inset 3px 4px 71px 0px rgba(24,255,255,1);
}

#overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 70px;
	background: #1d2c37;
	z-index: 11;
}