header {
	width: 100%;
	height: 70px;
	background: #25333c;
	padding-top: 17px;
	position: relative;

	img {
		position: absolute;
		left: 50px;
		top: 50%;
		transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		z-index: 111;

		@media (max-width: 1050px) {
			left: 50%;
			transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);			
		}
	}

	.fa-search {
		display: none;
		color: #FFF;
		float: right;
		margin-right: 25px;

		@media (max-width: 1050px) {
			display: block;
		}
	}

	div.form-group {
		width: 430px;
		display: block;
		margin: 0 auto;
		position: relative;

		@media (max-width: 1050px) {
			display: none;
			width: 80%;
			position: absolute;
			left: 50%;
			top: 80px;
			transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
		}

		&:before {
			content: "\f002";
		    font-family: FontAwesome;
		    font-style: normal;
		    font-weight: normal;
		    text-decoration: inherit; 
		    color: #C7C7CD;
		    font-size: 15px;
		    padding-right: 0.5em;
		    position: absolute;
		    top: 9px;
		    left: 14px;
		    z-index: 1;
		}

		input[type="text"] {
			width: 100%;
			height: 35px;
			background: #2a3a41;
			color: #FFF;
			border-radius: 18px;
			margin: 0 auto;
			outline: 0;
			border: 0;
			padding-left: 35px;
			display: block;
			position: relative;
		}
	}
}